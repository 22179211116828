import {
  CustomerGroupPerEmailDispatchTypes,
  CUSTOMER_GROUP_PER_EMAIL_LOADING,
  CUSTOMER_GROUP_PER_EMAIL_FAILURE,
  CUSTOMER_GROUP_PER_EMAIL_SUCCESS,
} from "../actions/customerGroupPerEmailActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  customerGroupPerEmail?: Array<string>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const customerGroupPerEmailReducer = (
  state: DefaultStateInterface = defaultState,
  action: CustomerGroupPerEmailDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case CUSTOMER_GROUP_PER_EMAIL_FAILURE:
      return {
        loading: false,
      };
    case CUSTOMER_GROUP_PER_EMAIL_LOADING:
      return {
        loading: true,
      };
    case CUSTOMER_GROUP_PER_EMAIL_SUCCESS:
      return {
        loading: false,
        customerGroupPerEmail: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type VehicleRootState = ReturnType<typeof customerGroupPerEmailReducer>;

export default customerGroupPerEmailReducer;
