import { ReactElement, ReactNode } from "react";
export const ALERT_HISTORY_LOADING = "ALERT_HISTORY_LOADING";
export const ALERT_HISTORY_FAILURE = "ALERT_HISTORY_FAILURE";
export const ALERT_HISTORY_SUCCESS = "ALERT_HISTORY_SUCCESS";

export type AlertHistory = {
  alert_config_id: number;
  triggered_at: string | null;
  alert_value: string | null;
  message: string | null;
  device_name: string | null;
  date: string | null;
  sub_customer: string | null;
  alert_name: string | null;
  asset_number: string | null;
};

export interface AlertHistoryLoading {
  type: typeof ALERT_HISTORY_LOADING;
}

export interface AlertHistoryFailure {
  type: typeof ALERT_HISTORY_FAILURE;
}

export interface AlertHistorySuccess {
  type: typeof ALERT_HISTORY_SUCCESS;
  payload: Array<AlertHistory>;
}

export type AlertHistoryDispatchTypes =
  | AlertHistoryLoading
  | AlertHistoryFailure
  | AlertHistorySuccess;
