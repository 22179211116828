import {
  AlertPreferencesDispatchTypes,
  ALERT_PREFERENCES_LOADING,
  ALERT_PREFERENCES_FAILURE,
  ALERT_PREFERENCES_SUCCESS,
  AlertPreferencesObject,
} from "../actions/alertPreferencesActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  alertPreferences?: Array<AlertPreferencesObject>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const alertPreferencesReducer = (
  state: DefaultStateInterface = defaultState,
  action: AlertPreferencesDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case ALERT_PREFERENCES_FAILURE:
      return {
        loading: false,
      };
    case ALERT_PREFERENCES_LOADING:
      return {
        loading: true,
      };
    case ALERT_PREFERENCES_SUCCESS:
      return {
        loading: false,
        alertPreferences: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type AlertPreferencesRootState = ReturnType<
  typeof alertPreferencesReducer
>;

export default alertPreferencesReducer;
