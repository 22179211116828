import {
  AlertHistoryDispatchTypes,
  ALERT_HISTORY_LOADING,
  ALERT_HISTORY_FAILURE,
  ALERT_HISTORY_SUCCESS,
  AlertHistory,
} from "../actions/alertHistoryActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  alertHistory?: Array<AlertHistory>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const alertHistoryReducer = (
  state: DefaultStateInterface = defaultState,
  action: AlertHistoryDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case ALERT_HISTORY_FAILURE:
      return {
        loading: false,
      };
    case ALERT_HISTORY_LOADING:
      return {
        loading: true,
      };
    case ALERT_HISTORY_SUCCESS:
      return {
        loading: false,
        alertHistory: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type AlertHistoryRootState = ReturnType<typeof alertHistoryReducer>;

export default alertHistoryReducer;
