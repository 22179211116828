export const ADMIN_LOADING = "ADMIN_LOADING";
export const ADMIN_FAILURE = "ADMIN_FAILURE";
export const ADMIN_SUCCESS = "ADMIN_SUCCESS";

export type AdminType = {
  emails: Array<AdminEntry>;
};

export type AdminEntry = {
  CustomerGroup: string;
  LastUpdate: string;
  UpdateBy: string;
  Emails: Array<string>;
  EmailDomain: Array<string>;
};

export interface AdminLoading {
  type: typeof ADMIN_LOADING;
}

export interface AdminFailure {
  type: typeof ADMIN_FAILURE;
}

export interface AdminSuccess {
  type: typeof ADMIN_SUCCESS;
  payload: Array<AdminEntry>;
}

export type AdminDispatchTypes = AdminLoading | AdminFailure | AdminSuccess;
