import {
  LogsDispatchTypes,
  LOGS_LOADING,
  LOGS_FAILURE,
  LOGS_SUCCESS,
  Logs,
} from "../actions/logsActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  logs?: Array<Logs>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const logsReducer = (
  state: DefaultStateInterface = defaultState,
  action: LogsDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case LOGS_FAILURE:
      return {
        loading: false,
      };
    case LOGS_LOADING:
      return {
        loading: true,
      };
    case LOGS_SUCCESS:
      return {
        loading: false,
        logs: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type LogsRootState = ReturnType<typeof logsReducer>;

export default logsReducer;
