export const DATE_SELECTION_LOADING = "DATE_SELECTION_LOADING";
export const DATE_SELECTION_SUCCESS = "DATE_SELECTION_SUCCESS";

export type DateSelections = {
  selectedStartDate: Date | undefined;
  selectedEndDate: Date | undefined;
};

export interface DateSelectionLoading {
  type: typeof DATE_SELECTION_LOADING;
}

export interface DateSelectionSuccess {
  type: typeof DATE_SELECTION_SUCCESS;
  payload: DateSelections;
}

export type DateSelectionDispatchTypes =
  | DateSelectionLoading
  | DateSelectionSuccess;
