import {
  TimestampWeatherDispatchTypes,
  TIMESTAMP_WEATHER_LOADING,
  TIMESTAMP_WEATHER_FAILURE,
  TIMESTAMP_WEATHER_SUCCESS,
  TimestampWeather,
} from "../actions/timestampWeatherActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  timestampWeather?: Record<string, TimestampWeather>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const timestampWeatherReducer = (
  state: DefaultStateInterface = defaultState,
  action: TimestampWeatherDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case TIMESTAMP_WEATHER_FAILURE:
      return {
        loading: false,
      };
    case TIMESTAMP_WEATHER_LOADING:
      return {
        loading: true,
      };
    case TIMESTAMP_WEATHER_SUCCESS:
      return {
        loading: false,
        timestampWeather: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type TimestampWeatherRootState = ReturnType<
  typeof timestampWeatherReducer
>;

export default timestampWeatherReducer;
