export const CUSTOMER_GROUP_PER_EMAIL_LOADING =
  "CUSTOMER_GROUP_PER_EMAIL_LOADING";
export const CUSTOMER_GROUP_PER_EMAIL_FAILURE =
  "CUSTOMER_GROUP_PER_EMAIL_FAILURE";
export const CUSTOMER_GROUP_PER_EMAIL_SUCCESS =
  "CUSTOMER_GROUP_PER_EMAIL_SUCCESS";

export type CustomerGroupPerEmailType = {
  emails: Array<string>;
};

export interface CustomerGroupPerEmailLoading {
  type: typeof CUSTOMER_GROUP_PER_EMAIL_LOADING;
}

export interface CustomerGroupPerEmailFailure {
  type: typeof CUSTOMER_GROUP_PER_EMAIL_FAILURE;
}

export interface CustomerGroupPerEmailSuccess {
  type: typeof CUSTOMER_GROUP_PER_EMAIL_SUCCESS;
  payload: Array<string>;
}

export type CustomerGroupPerEmailDispatchTypes =
  | CustomerGroupPerEmailLoading
  | CustomerGroupPerEmailFailure
  | CustomerGroupPerEmailSuccess;
