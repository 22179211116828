export const VEHICLE_LIST_LOADING = "VEHICLE_LIST_LOADING";
export const VEHICLE_LIST_FAILURE = "VEHICLE_LIST_FAILURE";
export const VEHICLE_LIST_SUCCESS = "VEHICLE_LIST_SUCCESS";

export interface VehicleListLoading {
  type: typeof VEHICLE_LIST_LOADING;
}

export interface VehicleListFailure {
  type: typeof VEHICLE_LIST_FAILURE;
}

export interface VehicleListSuccess {
  type: typeof VEHICLE_LIST_SUCCESS;
  payload: Array<any>;
}

export type VehicleListDispatchTypes =
  | VehicleListLoading
  | VehicleListFailure
  | VehicleListSuccess;
