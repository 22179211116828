import { ReactElement, ReactNode } from "react";
export const ALERT_PREFERENCES_LOADING = "ALERT_PREFERENCES_LOADING";
export const ALERT_PREFERENCES_FAILURE = "ALERT_PREFERENCES_FAILURE";
export const ALERT_PREFERENCES_SUCCESS = "ALERT_PREFERENCES_SUCCESS";

export type AlertPreferences = {
  AlertPreferences: Array<AlertPreferencesObject>;
};

export type AlertPreferencesObject = {
  alert_config_id: number;
  customer_name: string;
  alert_name: string;
  email_address: string;
  frequency: string | null;
  custom_threshold: number | null;
  is_active: boolean;
  sub_customer: string | null;
  custom_days: string | null;
};

export interface AlertPreferencesLoading {
  type: typeof ALERT_PREFERENCES_LOADING;
}

export interface AlertPreferencesFailure {
  type: typeof ALERT_PREFERENCES_FAILURE;
}

export interface AlertPreferencesSuccess {
  type: typeof ALERT_PREFERENCES_SUCCESS;
  payload: Array<AlertPreferencesObject>;
}

export type AlertPreferencesDispatchTypes =
  | AlertPreferencesLoading
  | AlertPreferencesFailure
  | AlertPreferencesSuccess;
