import {
  FleetDispatchTypes,
  FLEET_LOADING,
  FLEET_FAILURE,
  FLEET_SUCCESS,
  Fleet,
} from "../actions/fleetActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  fleet?: Array<Fleet>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const fleetReducer = (
  state: DefaultStateInterface = defaultState,
  action: FleetDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case FLEET_FAILURE:
      return {
        loading: false,
      };
    case FLEET_LOADING:
      return {
        loading: true,
      };
    case FLEET_SUCCESS:
      return {
        loading: false,
        fleet: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type FleetRootState = ReturnType<typeof fleetReducer>;

export default fleetReducer;
