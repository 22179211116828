import {
  DateSelectionDispatchTypes,
  DATE_SELECTION_LOADING,
  DATE_SELECTION_SUCCESS,
  DateSelections,
} from "../actions/dateSelectionActionTypes";

interface DefaultStateInterface {
  hasData?: boolean;
  DateSelections?: DateSelections;
  loading: boolean;
}

const defaultState: DefaultStateInterface = {
  hasData: false,
  loading: false,
  DateSelections: {
    selectedStartDate: undefined,
    selectedEndDate: undefined,
  },
};

const DateSelectionReducer = (
  state: DefaultStateInterface = defaultState,
  action: DateSelectionDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case DATE_SELECTION_LOADING:
      return {
        loading: true,
      };
    case DATE_SELECTION_SUCCESS:
      return {
        loading: false,
        DateSelections: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export default DateSelectionReducer;
