import { ReactElement, ReactNode } from "react";
export const RELAY_LOADING = "RELAY_LOADING";
export const RELAY_FAILURE = "RELAY_FAILURE";
export const RELAY_SUCCESS = "RELAY_SUCCESS";

export type Relay = {
  id: number;
  DCM: string;
  Timezone: string;
  LastUpdate: string;
  UpdateBy: string;
  AC: ACObject;
  EmergencyOff: boolean;
};

export type ACObject = {
  On: boolean;
  Weekdays?: StartEndObject;
  Weekend?: StartEndObject;
  Monday?: StartEndObject;
  Tuesday?: StartEndObject;
  Wednesday?: StartEndObject;
  Thursday?: StartEndObject;
  Friday?: StartEndObject;
  Saturday?: StartEndObject;
  Sunday?: StartEndObject;
};

export type StartEndObject = {
  on: string;
  off: string;
};

export interface RelayLoading {
  type: typeof RELAY_LOADING;
}

export interface RelayFailure {
  type: typeof RELAY_FAILURE;
}

export interface RelaySuccess {
  type: typeof RELAY_SUCCESS;
  payload: Array<Relay>;
}

export type RelayDispatchTypes = RelayLoading | RelayFailure | RelaySuccess;
