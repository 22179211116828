import {
  LastOnlineDispatchTypes,
  LAST_ONLINE_LOADING,
  LAST_ONLINE_FAILURE,
  LAST_ONLINE_SUCCESS,
  LastOnline,
} from "../actions/lastOnlineActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  lastOnline?: Array<LastOnline>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const lastOnlineReducer = (
  state: DefaultStateInterface = defaultState,
  action: LastOnlineDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case LAST_ONLINE_FAILURE:
      return {
        loading: false,
      };
    case LAST_ONLINE_LOADING:
      return {
        loading: true,
      };
    case LAST_ONLINE_SUCCESS:
      return {
        loading: false,
        lastOnline: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export default lastOnlineReducer;
