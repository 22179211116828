import {
  AdminDispatchTypes,
  ADMIN_LOADING,
  ADMIN_FAILURE,
  ADMIN_SUCCESS,
  AdminEntry,
} from "../actions/adminActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  emails?: Array<AdminEntry>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const adminReducer = (
  state: DefaultStateInterface = defaultState,
  action: AdminDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case ADMIN_FAILURE:
      return {
        loading: false,
      };
    case ADMIN_LOADING:
      return {
        loading: true,
      };
    case ADMIN_SUCCESS:
      return {
        loading: false,
        emails: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type AdminRootState = ReturnType<typeof adminReducer>;

export default adminReducer;
