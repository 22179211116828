import { ReactElement, ReactNode } from "react";
export const TIMESTAMP_WEATHER_LOADING = "TIMESTAMP_WEATHER_LOADING";
export const TIMESTAMP_WEATHER_FAILURE = "TIMESTAMP_WEATHER_FAILURE";
export const TIMESTAMP_WEATHER_SUCCESS = "TIMESTAMP_WEATHER_SUCCESS";

export type TimestampWeather = {
  icon: string;
  stateOfWeather: string;
  sunriseTime: number;
  sunsetTime: number;
  tempMax: number;
  tempMin: number;
};

export interface TimestampWeatherLoading {
  type: typeof TIMESTAMP_WEATHER_LOADING;
}

export interface TimestampWeatherFailure {
  type: typeof TIMESTAMP_WEATHER_FAILURE;
}

export interface TimestampWeatherSuccess {
  type: typeof TIMESTAMP_WEATHER_SUCCESS;
  payload: Record<string, TimestampWeather>;
}

export type TimestampWeatherDispatchTypes =
  | TimestampWeatherLoading
  | TimestampWeatherFailure
  | TimestampWeatherSuccess;
