import { ReactElement, ReactNode } from "react";
export const FLEET_LOADING = "FLEET_LOADING";
export const FLEET_FAILURE = "FLEET_FAILURE";
export const FLEET_SUCCESS = "FLEET_SUCCESS";

export type Fleet = {
  LastStealthVoltage: number;
  LastBMSVoltage: number;
  StealthSolarWh: number;
  BMSSolarWh: number;
  StealthLoadWh: number;
  BMSLoadWh: number;
  LastUpdate: string;
  UpdateBy: string;
  DCM: string;
  Location: string;
  CustomerGroup: string;
  Date: string;
  InstallDate: string;
  ActiveLoad: boolean;
  UnitGroup: string;
  UnitType: string;
  AssetNumber: string;
};

export interface FleetLoading {
  type: typeof FLEET_LOADING;
}

export interface FleetFailure {
  type: typeof FLEET_FAILURE;
}

export interface FleetSuccess {
  type: typeof FLEET_SUCCESS;
  payload: Array<Fleet>;
}

export type FleetDispatchTypes = FleetLoading | FleetFailure | FleetSuccess;
