import {
  RelayDispatchTypes,
  RELAY_LOADING,
  RELAY_FAILURE,
  RELAY_SUCCESS,
  Relay,
} from "../actions/relayActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  resetRelay?: Array<Relay>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const relayReducer = (
  state: DefaultStateInterface = defaultState,
  action: RelayDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case RELAY_FAILURE:
      return {
        loading: false,
      };
    case RELAY_LOADING:
      return {
        loading: true,
      };
    case RELAY_SUCCESS:
      return {
        loading: false,
        resetRelay: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type RelayRootState = ReturnType<typeof relayReducer>;

export default relayReducer;
